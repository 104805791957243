import React from "react";
import logo from "../../assets/hyfen8-logo.png";

export default function UserAgreement() {
  return (
    <div className="user-agreement-page">
      <div className="container">
        <h1 className="head-2 pb-5">User Agreement</h1>
        <div className="agreement-wrapper">
          <div className="pb-3">
            <h4>Website Terms of Use</h4>
            <p>
              Welcome to Hyfen8! Before you use our site, please read these Terms of Use, as they contain important information. This platform (<b>Platform</b>)
              is operated by Hyfen Hub Pty Ltd (ABN: 30 645 094 079) (<b>we</b>, <b>our</b> or <b>us</b>). These website terms of use apply to your use of, and
              access to, the Platform.
            </p>
          </div>

          <div className="content-container">
            <h5>Our Disclosures</h5>
            <div className="disclosures">
              <p>
                Our complete terms and conditions are contained below, but some important points for you to know before you use our Platform are set out below:
              </p>
              <ul>
                <li>We may amend these Terms at any time;</li>
                <li>
                  We may receive a benefit (which may include a referral fee or a commission) should you visit certain third-party websites via a link on the
                  Platform or for featuring certain products or services on the Platform;
                </li>
                <li>
                  We will handle your personal information in accordance with our privacy policy, available at{" "}
                  <a href="http://hyfen8.surge.sh/data-privacy">hyfen8.com/data&privacy</a>. We may disclose your personal information to third parties;
                </li>
                <li>
                  To the maximum extent permitted by law (including the Australian Consumer Law), we exclude all liability for any loss or damage of any kind
                  whether under statute, contract, equity, tort (including negligence), indemnity or otherwise arising out of or in connection with the Platform
                  or the Content;
                </li>
                <li>We may, at any time and without notice, discontinue the Platform (in whole or in part), or exclude any person from using our Platform;</li>
                <li>
                  We do not warrant the accuracy, completeness or suitability of any of the Content and to the maximum extent permitted by law we are not liable
                  for any loss resulting from any reliance on the Content on our Platform; and
                </li>
                <li>
                  To the maximum extent permitted by law, you indemnify us, and hold us harmless, against any liability suffered or incurred by us arising from
                  or in connection with your use of our Platform or any breach of these Terms or any applicable laws by you.
                </li>
              </ul>
              <p>Nothing in these Terms limit your rights under the Australian Consumer Law.</p>
              <br />
            </div>

            <h5>Background</h5>
            <p>The Platform is intended to provide you with information related to our services, and enable you to contact us.</p>
            <br />

            <h5>Consent</h5>
            <p>
              By clicking “I accept”, accessing and/or using our Platform, you agree to these Terms of Use and our Privacy Policy (available on our Platform) (
              <b>Terms</b>). Please read these Terms carefully and immediately cease using our Platform if you do not agree to them.
            </p>
            <p>
              You must be over the age of 18 to use our Platform. By accessing and/or using our Platform, you agree, warrant and represent that you are over 18
              years of age.
            </p>
            <br />
            <h5>Variations</h5>
            <p>
              We may, at any time and at our discretion, vary these Terms by publishing the varied terms on our Platform. We recommend you check our Platform
              regularly to ensure you are aware of our current terms.
            </p>
            <br />
            <h5>Licence to use our Platform</h5>
            <p>
              We grant you a non-exclusive, royalty-free, revocable, worldwide, non-transferable licence to use our Platform in accordance with these Terms. All
              other uses are prohibited without our prior written consent.
            </p>
            <br />
            <h5>Information and availability</h5>
            <p>
              While we use reasonable attempts to ensure the accuracy and completeness of the content and materials on the Platform (<b>Content</b>), to the
              extent permitted by law (including the Australian Consumer Law), we do not warrant the accuracy, completeness or suitability of any of the
              Content. The Content may be subject to change without notice and we do not undertake to keep the Platform up-to-date. The Content is factual
              information only, is not comprehensive and is for general information purposes only. We also do not warrant that access to the Platform will be
              uninterrupted, error-free or free from viruses.
            </p>
            <br />
            <h5>Intellectual Property rights</h5>
            <p>
              Unless otherwise indicated, we own or license the Content and all intellectual property rights (including any copyright, registered or
              unregistered designs, illustrations, artwork, patents or trade mark or logo rights and domain names) displayed or used on the Platform (
              <b>Our Intellectual Property</b>).
            </p>
            <p>
              We authorise you to access and use the Platform and Content solely for your own personal, non-commercial use and to display, print and download
              the Content onto your personal device provided that you do not remove any copyright notice included in Our Intellectual Property.
            </p>
            <p>
              You must not use the Platform, or any of the Content, for your commercial purposes, including, for example, to advertise your own business or for
              any other revenue generation activity and you must not use the Platform, including the Content, in any way that competes with our business.
            </p>
            <p>
              Subject to the above, your use of, and access to, the Platform and the Content does not grant or transfer to you any rights, title or interest to
              Our Intellectual Property. Unless otherwise permitted in these Terms, you must not:
            </p>
            <ol>
              <li>copy or use, in whole or in part, any of Our Intellectual Property;</li>
              <li>reproduce, retransmit, distribute, display, disseminate, sell, publish, broadcast or circulate any of Our Intellectual Property; or</li>
              <li>
                breach any intellectual property rights connected with Our Intellectual Property, including altering or modifying any of Our Intellectual
                Property, causing any of Our Intellectual Property to be framed or embedded in another website or platform, or creating derivative works from
                Our Intellectual Property.
              </li>
            </ol>
            <p>
              Nothing in the above clause restricts your ability to publish, post or repost Content or Our Intellectual Property on your social media page or
              blog, provided that:
            </p>
            <ol>
              <li>you do not assert that you are the owner of the Content or Our Intellectual Property;</li>
              <li>unless explicitly agreed by us in writing, you do not assert that you are endorsed or approved by us;</li>
              <li>you do not damage or take advantage of our reputation, including in a manner that is illegal, unfair, misleading or deceptive; and</li>
              <li>you comply with all other terms of these Terms.</li>
            </ol>
            <br />
            <h5>Conduct we don’t accept</h5>
            <p>
              You must not do or attempt to do anything that is unlawful, which is prohibited by applicable law, which we would consider inappropriate or which
              might bring us or the Platform into disrepute. This includes:
            </p>
            <ol>
              <li>
                anything that would constitute a breach of an individual’s privacy (including uploading private or personal information without an individual's
                consent) or any other legal rights;
              </li>
              <li>using the Platform to defame, harass, threaten, menace or offend any person;</li>
              <li>using the Platform for unlawful purposes;</li>
              <li>interfering with any user of the Platform;</li>
              <li>
                tampering with or modifying the Platform, knowingly transmitting viruses or other disabling features, or damaging or interfering with the
                Platform, including using trojan horses, viruses or piracy or programming routines that may damage or interfere with the Platform;
              </li>
              <li>using the Platform to send unsolicited electronic messages;</li>
              <li>using data mining, robots, screen scraping or similar data gathering and extraction tools on the Platform; or</li>
              <li>facilitating or assisting a third party to do any of the above acts.</li>
            </ol>
            <br />
            <h5>Content you upload</h5>
            <p>
              We encourage you to interact with the Platform and with us on social media! You may be permitted to post, upload, publish, submit or transmit
              relevant information and content (<b>User Content</b>) on the Platform. We may run campaigns via the Platform and via social media that encourage
              you to post User Content on social media using specific hashtags (#) (<b>Tag</b>
              ).
            </p>
            <p>You own all User Content.</p>
            <p>You grant us a limited, irrevocable licence to copy, transmit, store, backup and/or otherwise access or use User Content to:</p>
            <ol>
              <li>communicate with you (including to send you information we believe may be of interest to you);</li>
              <li>supply the Platform to you and otherwise perform our obligations under these Terms;</li>
              <li>diagnose problems with the Platform;</li>
              <li>enhance and otherwise modify the Platform;</li>
              <li>perform Analytics;</li>
              <li>develop and provide other services, provided we de-identify User Content; and</li>
              <li>as reasonably required to perform our obligations under these Terms.</li>
            </ol>
            <p>You acknowledge and agree that: </p>
            <ol>
              <li>we are not responsible for the integrity or existence of any data on the Platform, or any network or any device controlled by you; and</li>
              <li>
                we assume no responsibility or Liability for User Content. You are solely responsible for User Content and the consequences of using,
                disclosing, storing or transmitting it.
              </li>
            </ol>
            <p>
              You acknowledge and agree that we may monitor, analyse and compile statistical and performance information based on and/or related to the User
              Content, in an aggregated and anonymised format (<b>Analytics</b>). You acknowledge and agree that we may use the Analytics for our own business
              purposes, provided that the Analytics do not contain any identifying information.
            </p>
            <p>
              We, and our licensors own all right, title and interest in and to the Analytics and all related software, technology, documentation and content
              used or provided in connection with the Analytics, including all Intellectual Property Rights in the foregoing.
            </p>
            <p>We do not endorse or approve, and are not responsible for, any User Content.</p>
            <p>
              You acknowledge and agree that we are not responsible, and you will not hold us liable for, another person’s use of User Content. We disclaim all
              Liability, and you waive and release us from any and all Liability, arising from you allowing and/or refusing access to User Content by another
              person and/or another person’s use of User Content.
            </p>
            <p>
              You agree that you are solely responsible for all User Content that you make available on or through the Platform, including on social media using
              a Tag. You represent and warrant that:
            </p>
            <ol>
              <li>
                you are either the sole and exclusive owner of all User Content or you have all rights, licences, consents and releases that are necessary to
                grant to us the rights in such User Content (as contemplated by these Terms); and
              </li>
              <li>
                neither the User Content nor the posting, uploading, publication, submission or transmission of the User Content or our use of the User Content
                on, through or by means of our Platform (including on social media) will infringe, misappropriate or violate a third party’s intellectual
                property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
              </li>
            </ol>
            <p>
              We do not endorse or approve, and are not responsible for, any User Content. We may, at any time (at our sole discretion), remove any User Content
            </p>
            <p>
              To the maximum extent permitted by law, you are liable for, and agree to indemnify us and hold us harmless in respect of, any liability that we
              may suffer, incur or otherwise become liable for, arising from or in connection with any claim by a person that any of your User Content infringes
              the intellectual property rights of any person.
            </p>
            <p>This clause will survive the termination or expiry of these Terms.</p>
            <br />
            <h5>Third party sites</h5>
            <p>
              The Platform may contain links to websites operated by third parties. Unless we tell you otherwise, we do not control, endorse or approve, and are
              not responsible for, the content on those websites. We recommend that you make your own investigations with respect to the suitability of those
              websites. If you purchase goods or services from a third-party website linked from the Platform, such third party provides the goods and services
              to you, not us.
            </p>
            <p>
              We may receive a benefit (which may include a referral fee or a commission) should you visit certain third-party websites via a link on the
              Platform (<b>Affiliate Link</b>) or for featuring certain products or services on the Platform. We will make it clear by notice to you which (if
              any) products or services we receive a benefit to feature on the Platform, or which (if any) third party links are Affiliate Links.
            </p>
            <br />
            <h5>Indemnity</h5>
            <p>
              To the maximum extent permitted by law, you indemnify us, and hold us harmless, against any Liability suffered or incurred by us arising from or
              in connection with your use of our Platform or any breach of these Terms or any applicable laws by you. This indemnity is a continuing obligation,
              independent from the other obligations under these Terms, and continues after these Terms end. It is not necessary for us to suffer or incur any
              liability before enforcing a right of indemnity under these Terms.
            </p>
            <br />
            <h5>Our liability is limited</h5>
            <p>
              You may have certain rights under the Australian Consumer Law in relation to the Platform and the Content, and you may contact us for more
              information about this. Despite anything to the contrary, to the maximum extent permitted by law (including the Australian Consumer Law), we
              exclude all liability for any loss or damage of any kind (including consequential loss, indirect loss, loss of profit, loss of benefit, loss of
              opportunity or loss of reputation) whether under statute, contract, equity, tort (including negligence), indemnity or otherwise arising out of or
              in connection with the Platform or the Content.
            </p>
            <br />
            <h5>Warranties and Disclaimer</h5>
            <p>
              To the maximum extent permitted by law, we make no representations or warranties about our Platform or the Content, including (without limitation)
              that:
            </p>
            <ol>
              <li>they are complete, accurate, reliable, up-to-date and suitable for any particular purpose;</li>
              <li>access will be uninterrupted, error-free or free from malware; or</li>
              <li>our Platform will be secure.</li>
            </ol>
            <p>You read, use and act on our Platform and the Content at your own risk.</p>
            <br />
            <h5>Privacy</h5>
            <p>
              We respect your privacy and understand protecting your personal information is important. Our Privacy Policy (available on the Platform) sets out
              how we will collect and handle your personal information.
            </p>
            <br />
            <h5>What happens if we discontinue the Platform and Termination</h5>
            <p>
              We may, at any time and without notice, discontinue the Platform (in whole or in part), or exclude any person from using our Platform. These Terms
              are effective until terminated by us, which we may do at any time and without notice to you. In the event of termination, all restrictions imposed
              on you by these Terms and limitations of liability set out in these Terms will survive.
            </p>
            <br />
            <h5>Disputes</h5>
            <p>
              In the event of any dispute arising from, or in connection with, these Terms (<b>Dispute</b>), the party claiming there is a Dispute must give
              written notice to the other party setting out the details of the Dispute and proposing a resolution. Within 7 days after receiving the notice, the
              parties must, by their senior executives or senior managers (who have the authority to reach a resolution on behalf of the party), meet at least
              once to attempt to resolve the Dispute or agree on the method of resolving the Dispute by other means, in good faith. All aspects of every such
              conference, except the fact of the occurrence of the conference, will be privileged. If the parties do not resolve the Dispute, or (if the Dispute
              is not resolved) agree on an alternate method to resolve the Dispute, within 21 days after receipt of the notice, the Dispute may be referred by
              either party (by notice in writing to the other party) to litigation.
            </p>
            <br />
            <h5>Severance</h5>
            <p>
              If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision must be read down as narrowly as necessary to
              allow it to be valid or enforceable. If it is not possible to read down a provision (in whole or in part), that provision (or that part of that
              provision) is severed from these Terms without affecting the validity or enforceability of the remainder of that provision or the other provisions
              in these Terms.
            </p>
            <br />
            <h5>Which laws govern these Terms</h5>
            <p>
              These Terms are governed by the laws of Victoria. Each party irrevocably and unconditionally submits to the exclusive jurisdiction of the courts
              operating in Victoria and any courts entitled to hear appeals from those courts and waives any right to object to proceedings being brought in
              those courts.
            </p>
            <br />
            <h5>Changes to these Terms</h5>
            <p>
              We may, at any time and at our discretion, vary these Terms by publishing the varied terms on the Platform. We recommend you check the Platform
              regularly to ensure you are aware of our current terms.
            </p>
            <br />
            <h5>For any questions and notices, please contact us at:</h5>
            <p>
              Email: <a href="mailto:support@hyfen8.com">support@hyfen8.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
