import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import UserAgreement from "../components/SignUp/UserAgreement";

const UserAgreementPage = () => {
  return (
    <Layout title={"User agreement"}>
      <Navbar />
      <UserAgreement />
      <Footer />
    </Layout>
  );
};

export default UserAgreementPage;
